import { Card, Stack, Box, Dialog, DialogTitle, DialogContent, Snackbar, Alert, Divider, DialogActions, Button, TextField, CardContent, CardActions, LinearProgress, MobileStepper, Typography, CircularProgress } from "@mui/material";
import {useForm, Controller} from 'react-hook-form';
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Section from "./Section";

import { useNavigate } from "react-router-dom";
import jax from "../../helper/jax";
import { ArrowBack, ArrowForward, HealthAndSafety } from "@mui/icons-material";
import { evaluate } from "../../helper/util";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const EvalFooter = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

const EvalHeader = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

const EvalIntro = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

const EvalOutro = function(props) {
	return <Box sx={{flex:1}}>{props.children}</Box>;
}

export default function Eval(props) {
	var isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
	const { step, course_code, schema, schema_id, enrollment, eval_type, role_id, onClose, is_lead, onSubmitted, maxBodyHeight, finishText, embedded, embedProps, maxWidth, footerMessage, show } = props;
	const [section, setSection] = useState(step || -1); 

	
	const [evalType, setEvalType] = useState(eval_type);
	const [courseCode, setCourseCode] = useState(course_code);
	const [course, setCourse] = useState();

	const [answers, setAnswers] = useState();
	const [content, setContent] = useState();
	const [invalid, setInvalid] = useState({});
	const [showInvalid, setShowInvalid] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [showError, setShowError] = useState(false);
	const user = useSelector((state) => state.data.user);

	const theme = useTheme();
	var footer = <></>;
	var header = <></>;
	var intro = null;
	var outro = null;
	React.Children.forEach(props.children, (child) => {
		if (React.isValidElement(child)) {
			if (child.type === EvalFooter) {
				footer = child;
			} else if (child.type === EvalHeader) {
				header = child;
			} else if (child.type === EvalIntro) {
				intro = child;
			} else if (child.type === EvalOutro) {
				outro = child;
			}
		}
	});

	useEffect(()=>{
		if (show && (schema_id || schema || role_id || evalType) && !content ) {
			begin();
		}
	}, [role_id, courseCode, evalType, show, schema, schema_id]);

	// useEffect(()=>{
	// 	if (content && step && !isNaN(step)) {
	// 		setSection(parseInt(step));
	// 	}
	// }, [step,content]);

	const go = function(to) {
		setSection(to);
		scrollToTop();
	}

	const begin = async function() {
		var invalid = {};
		var content = schema;
		if (!content) {
			if (!!courseCode) {
				var course = await jax.get('/app/course_eval/'+courseCode+'/'+(evalType ?? role_id), enrollment ? {enrollment: 1} : {});
				content = course.schema;
			} else if (schema_id) {
				var resp = await jax.get('/app/evaluations/__'+schema_id);
				content = resp.schema;
			}
		}
		
		if (content) {
			content.sections.map(s=>s.blocks = s.blocks.filter(b=>!b.lead_instructor || is_lead));
			setEvalType(content.type_id);
			setContent(content);

			//Reset answers if course or role changes
			if (!answers || answers.course_code != course_code || answers.role_id != role_id) { 
				var empty = {
					course_code: course_code,
					role_id: role_id,
					eval_type: content.id, 
					answers: {} 
				}
				
				content.sections.map((section, i) => {
					empty.answers[section.eval_schema_id] ||= {};
					invalid[section.eval_schema_id] ||= {};
					section.blocks.map((block, j) => {
						empty.answers[section.eval_schema_id][block.block_id] = {};
						empty.answers[section.eval_schema_id][block.block_id] = {};
						if (block.type == 'likert') {
							empty.answers[section.eval_schema_id][block.block_id]._type = 'likert';
						}
						block.questions.map((question, k) => {
							empty.answers[section.eval_schema_id][block.block_id][question.answer_id] = null;
							if (question.allow_other) {
								empty.answers[section.eval_schema_id][block.block_id][question.answer_id+'_OTHER'] = null;
							}
						});
					});
				});
				setAnswers(empty);
				setInvalid(invalid);
			}

			go(!!intro ? -1 : 0);
			
		}
	} 

	const submit = async function(e) {
		e.preventDefault();
		if (validate(section)) {
			var url = '/app/evals/'+courseCode;
			if (role_id) {
				url += '/'+role_id;
			}

			try {
				setSubmitting(true);
				await jax.post(url, {answers: answers.answers, eval_type: answers.eval_type, eval_type_code: evalType, uid: user?.uid, schema_id: content.id, enrollment: enrollment?1:0});
				
				setSubmitted(true);
				if (onSubmitted) {
					onSubmitted();
				}
				setSubmitting(false);
			} catch (err) {
				setSubmitting(false);
				setErrorMessage("An unexpected error occurred while saving your responses. Please try again.");
				setShowError(true);
				setTimeout(()=>setShowError(false), 5000);
				
				console.error(err);
			} finally {
				setSubmitting(false);
			}

			
		}
	}

	const {control, trigger, handleSubmit, register, watch, formState: { errors }} = useForm({
	});

	const validate = function(sectionId) {
		
		var valid = true;
		var inv = {...invalid};
		if (sectionId < 0) return true;
		let section = content.sections[sectionId];
		section.blocks.map((block, i) => {
			
			//Don't check hidden blocks
			if (block.condition && !evaluate(block.condition, { ...answers.answers[section.eval_schema_id], _course_code: answers.course_code, _role: answers.role_id, _is_lead: is_lead})) return;

			block.questions.map((question, j) => {
				if ((block.required || question.required) && (!answers.answers[section.eval_schema_id][block.block_id] || !answers.answers[section.eval_schema_id][block.block_id][question.answer_id])) {
					valid = false;
					inv[section.eval_schema_id][block.block_id] ||= {};
					inv[section.eval_schema_id][block.block_id][question.answer_id] = true;
					//setInvalid({block_id: block.block_id, answer_id: question.answer_id});
				} else {
					try {
						delete inv[section.eval_schema_id][block.block_id][question.answer_id];
					} catch {
						
					}
				}
			});
		});
		
		setInvalid(inv);
		if (!valid) {
			showErrorMessage('One or more required questions have not been answered.')
		}
		//setShowInvalid(!valid);
		return valid;
	}

	const showErrorMessage = (message) =>{
		setErrorMessage('One or more required questions have not been answered.');
		setShowError(true);
		setTimeout(()=>setShowError(false), 5000);
	}

	const recordAnswer = function(answer) {
		var block = answers.answers[answer.schema_id][answer.block_id];
		block[answer.answer_id] = answer.answer;
		invalid[answer.schema_id][answer.block_id] ||= {};
		delete invalid[answer.schema_id][answer.block_id][answer.answer_id];
		setAnswers({...answers, answers: {...answers.answers}});
		setInvalid(invalid);
	}

	const next = async function() {
		if (validate(section)) {
			go(section+1);
		}
	}

	const contentRef = useRef();

	const scrollToTop = function() {
		if (contentRef.current) {
			contentRef.current.scrollTop = 0
		}
	}

	const close = function() {
		if (onClose) {
			onClose();
		}
		reset();
	}

	const reset = function() {		
		setSection(-1);
		setSubmitted(false);
		setAnswers(null);
		setContent(null);
		setInvalid({});
		setShowInvalid(false);
		setSubmitting(false);
		setErrorMessage(null);
		setShowError(false);
	}


	const dcontent = <>
			<DialogTitle sx={{my:0, p:0}}>
				<Box p={2} className="card-header">{content?.name || 'Course Evaluation'}</Box>
				<Box flex={1} maxWidth={maxWidth}>{!!header && header}</Box>
				{!submitted && section > -1 && <Stack direction="row" className="section-title" justifyContent="space-between" alignItems="center" sx={{p:2}}>
					{content.sections[section]?.title && <Box>{content.sections[section].title}</Box>}
					<Box flex={1}></Box>
					{section > -1 && <Box color={theme.palette.primary.main}>Step {section+1} {isMobile ? "/" : "of"} {content.sections.length}</Box>}
				</Stack>}
			</DialogTitle>
			
			<DialogContent flex={1} sx={{px:0, p:0, maxWidth: {maxWidth}, minHeight: 400, maxHeight: maxBodyHeight, position:'relative'}} ref={contentRef}>
				{content ? <form onSubmit={submit}>
					{!submitted ? <Box>
							{section < 0 && !!intro && intro}
							{section !== undefined && section > -1 && content && <Box className="banana"><Section onAnswer={recordAnswer} answers={answers.answers[content.sections[section].eval_schema_id]} invalid={invalid[content.sections[section].eval_schema_id]} is_lead={is_lead} section={content.sections[section]}></Section></Box>}
						</Box> : <Box sx={{p: 1, pb:8}}>
							{outro}
						</Box>}
				</form> : <Box p={12} textAlign="center">
						<CircularProgress></CircularProgress>
				</Box>}

				
			</DialogContent>
			
			
			<Snackbar open={showInvalid} autoHideDuration={6000} onClose={()=>setShowInvalid(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} sx={{position:"absolute", bottom: '84px !important'}} >
				<Alert severity="error" variant="filled" sx={{ width: '100%' }}>
					One or more required questions have not been answered.
				</Alert>
			</Snackbar>
			<Divider sx={{my:0}}></Divider>
			<DialogActions sx={{p:0, position:'relative'}}>
				<Box className={`eval-error ${showError && "shown"}`} sx={{ backgroundColor: theme.palette.error.main, color: 'white' }} >
					<span style={{color:'white'}}>{errorMessage}</span>
				</Box>
				<Box p={2}><Button disabled={true}>&nbsp;</Button></Box>
			</DialogActions>
			<DialogActions sx={{p:0, position:'absolute', bottom: 0, right: 0, left: 0, background: 'white', zIndex:2}}>
				
				<Stack direction="column" flex={1} maxWidth={maxWidth} alignItems="stretch">
				{!!footer && footer}
				{!submitted ? <Stack flex={1} direction="row" justifyContent="space-between" sx={{p:2}}>
					{!embedded && <Button onClick={close}>Cancel</Button>}
					<Box flex={1}></Box>
					{content && (section > 0 || (intro && section > -1)) && <Button variant="outlined" onClick={()=>go(section-1) } sx={{mx:1}} startIcon={<ArrowBack/>}>Back</Button>}
					{content  && section < content.sections.length-1 && <Button variant="contained" sx={{mx:1}} onClick={()=>next()} endIcon={<ArrowForward/>}>Continue</Button>}
					{content && section == content.sections.length-1 ? <Button variant="contained" disabled={submitting} onClick={submit} sx={{mx:1}} >{finishText || "Submit Responses"}</Button> : <></>}
				</Stack> : <Box flex={1} p={2} textAlign="center"><Button variant="contained" onClick={close} sx={{mx:1}}>Close</Button></Box>
				}
				</Stack>
			</DialogActions>
		</>;

		return embedded ? <Box position="relative" {...embedProps}>
			{dcontent}
		</Box> : <Dialog fullScreen={isMobile} maxWidth="md" fullWidth open={!!show} className="eval" onClose={close}>
			{dcontent}
		</Dialog>
	;
}

export {EvalFooter, EvalIntro, EvalOutro, EvalHeader};